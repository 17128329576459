<template>
    <v-container>
        <v-card-title> {{$vuetify.lang.t('$vuetify.receiving.purchaseItem')}}</v-card-title>
        <v-card-text>
            <v-select
                    v-model="purchaseItem"
                    :items="purchaseItems"
                    :label="$vuetify.lang.t('$vuetify.receiving.purchaseItem')"
                    @change="purchaseItemChange"
            >
                <template v-slot:append-item>
                    <v-divider class="ma-4"></v-divider>
                    <v-btn small class="primary ml-4" @click.stop="createDialog = true">
                        {{$vuetify.lang.t('$vuetify.receiving.createNewItem')}}
                    </v-btn>
                </template>
            </v-select>
        </v-card-text>

        <v-dialog v-model="createDialog" persistent>
            <v-card>
                <v-card-title>
                    {{$vuetify.lang.t('$vuetify.receiving.createNewItem')}}
                </v-card-title>
                <v-card-text>
                    <v-treeview
                            rounded
                            hoverable
                            :items="products"
                            :activatable="activatable"
                            open-on-click="true"
                            @update:active="addProduct"
                    ></v-treeview>
                </v-card-text>
                <v-card-actions>
                    <v-btn small text @click.prevent.stop="createDialog = !createDialog"> {{$vuetify.lang.t('$vuetify.receiving.cancel')}}</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </v-container>
</template>

<script>
    export default {
        props: ["purchaseItems", "purchaseItem"],
        data: () => ({
            createDialog: false,
            activatable: true,
            products: []
        }),
        created() {
            this.$axios.get(
                "category/childs",
                {
                    params: {
                        code: "G_PURCHASED_PRODUCT"
                    }
                }
            ).then((response) => {
                var data = response.data;
                if (data.code != 200) {
                    let message = data.message
                    this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                    return;
                } else {
                    this.products = data.data;
                }
            });
        },
        methods: {
            addProduct(categoryIds) {
                console.info(categoryIds);
                let categories = [];
                for (let j = this.products.length - 1; j >= 0; j--) {
                    categories.push(this.products[j]);
                }
                while (categories.length !== 0) {
                    let category = categories.pop();
                    if (category.id === categoryIds[0]) {
                        let children = category.children;
                        if (children != null && children.length > 0) {
                            let message = "NOT A LEAF"
                            this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                            return;
                        }
                        this.createDialog = false;
                        let purchaseItem = {
                            productId: category.id,
                            productName: category.name,
                            step: "INIT"
                        };
                        this.$emit('updatePurchaseItem', purchaseItem);
                    }
                    let children = category.children;
                    if (children != null) {
                        for (let i = children.length - 1; i >= 0; i--) {
                            categories.push(children[i]);
                        }
                    }

                }
            },
            isLeaf(categoryId) {
                let categories = [];
                for (let j = this.products.length - 1; j >= 0; j--) {
                    categories.push(this.products[j]);
                }
                while (categories.length !== 0) {
                    let category = categories.pop();
                    if (category.id === categoryId) {
                        let children = category.children;
                        if (children != null && children.length > 0) {
                            return false;
                        }
                        return true
                    }
                    let children1 = category.children;
                    if (children1 != null) {
                        for (let i = children1.length - 1; i >= 0; i--) {
                            categories.push(children1[i]);
                        }
                    }
                }
            },
            purchaseItemChange() {
                this.$emit('purchaseItemChange', this.purchaseItem);
            }
        }
    }
</script>
